import React, {useEffect, useMemo, useState} from 'react';
import Header from "../Header";
import {Grid} from "@mui/material";
import TextInput from "../Inputs/TextInput";
import {Button} from "@material-ui/core";
import useServiceUrls from "../../Utils/UseServiceUrls";
import {closeWindow, getQueryString, onSnippetComplete} from "../../Helpers/Globals";
import useAvailableCoupons from "../../Utils/UseAvailableCoupons";
import useApplyCoupon from "../../Utils/UseApplyCoupon";
import LoadingSpinner from "../LoadingSpinner";
import useGetCart from "../../Utils/UseGetCart";
import ClickableRowTable from "../Tables/ClickableRowTable";
import useRemoveCoupon from "../../Utils/UseRemoveCoupon";
import CloseButton from "../PageControls/CloseButton";
import useCoupon from "../../Utils/UseCoupon";

export default function ApplyCoupon(props) {
    const [sessionKey, setSessionKey] = useState('');
    const [loginDomain, setLoginDomain] = useState('');
    const [cartUid, setCartUid] = useState('');
    const [cartExternalId, setCartExternalId] = useState('');
    const [cartSourceExternalId, setCartSourceExternalId] = useState('');
    const [returnUrl, setReturnUrl] = useState('')
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(loginDomain);
    const { status: cartStatus, cartResponse, error: cartError, refetch } = useGetCart(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId);
    const {status: couponsStatus, coupons, error: couponsError} = useAvailableCoupons(serviceUrls, sessionKey, cartUid, cartExternalId, cartSourceExternalId)
    const [searchCode, setSearchCode] = useState('')
    const {status: couponStatus, coupon, error: couponError} = useCoupon(serviceUrls, sessionKey, searchCode); 
    
    const apply = useApplyCoupon()
    const remove = useRemoveCoupon()
    
    const [search, setSearch] = useState('')
    const [options, setOptions] = useState([])
    const [showOptions, setShowOptions] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState('')
    const [exactMatch, setExactMatch] = useState(false)
    
    const appliedCoupons = useMemo(() => {
        return cartResponse?.LoyaltySummary.AppliedCoupons ?? []
    }, [cartResponse, cartStatus])
    
    useEffect(() => {
        setSessionKey(getQueryString(window.location.href, 'bl_sk') ?? getQueryString(window.location.href,'SessionKey') ?? '');
        setLoginDomain(getQueryString(window.location.href, 'bl_LoginDomain') ?? getQueryString(window.location.href,'LoginDomain') ?? '');
        setReturnUrl(getQueryString(window.location.href, 'bl_rurl') ?? getQueryString(window.location.href,'ReturnUrl') ?? '')
        setCartUid(getQueryString(window.location.href, 'bL_CartUid') ?? getQueryString(window.location.href,'CartUid') ?? '');
        setCartExternalId(getQueryString(window.location.href, 'bl_CartExternalId') ?? getQueryString(window.location.href,'cartExternalId') ?? '')
        setCartSourceExternalId(getQueryString(window.location.href, 'bl_CartSourceExternalId') ?? getQueryString(window.location.href,'CartSourceExternalId') ?? '')
    }, [window.location.href])
    
    useEffect(() => {
        if (couponStatus === 'success') {
            if (coupon)
                applyCoupon(coupon);
            else
                setShowOptions(true)
        }
    }, [couponStatus])
    
    useEffect(() => {
        if (apply.status === 'success') {
            refetch()
            setSearch('')
            setSelectedCoupon('')
            if (exactMatch) {
                onSnippetComplete(returnUrl)
                setExactMatch(false)
            }
        }
    }, [apply.status])

    useEffect(() => {
        if (remove.status === 'success') {
            refetch()
            setSearch('')
            setSelectedCoupon('')
        }
    }, [remove.status])
    
    const handleChange = (field, value) => {
        setSearch(value)
    }
    
    const findCoupons = (e) => {
        e.preventDefault()
        const coupon = coupons?.find((c) => c.Code.toLowerCase() === search.toLowerCase())
        if (coupon) {
            applyCoupon(coupon)
            if (selectedCoupon === coupon.Uid)
                setExactMatch(false)
            else
                setExactMatch(true)
        }
        else 
            getCouponOptions()
    }
    
    const getCouponOptions = () => {
        const opts = coupons?.filter((c) => c.Name.toLowerCase().includes(search.toLowerCase()) || c.Code.toLowerCase().includes(search.toLowerCase()))
        if (opts.length === 0)
            setOptions([])
        else
            setOptions(opts)
        
        if (search === '')
            setShowOptions(true)
        else
            setSearchCode(search)
    }
    
    const applyCoupon = (coupon) => {
        hideOptions()
        apply.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, uid: coupon.Uid, code: coupon.Code, quantity: 1, accessKey: sessionKey})
    }
    
    const removeCoupon = () => {
        const coupon = cartResponse?.LoyaltySummary?.AppliedCoupons?.find((c) => c.Uid === selectedCoupon)
        const quantity = coupon?.QuantityApplied
        remove.mutate({urls: serviceUrls, cartUid, cartExternalId, cartSourceExternalId, uid: coupon.Uid, code: coupon.Code, quantity: quantity === 1 || quantity === 0 ? null : 1, accessKey: sessionKey})
    }
    
    const hideOptions = () => {
        setShowOptions(false)
        setOptions([])
    }
    
    const onAppliedTableRowClick = (coupon) => {
        if (selectedCoupon === coupon.Uid)
            setSelectedCoupon('')
        else
            setSelectedCoupon(coupon.Uid)
    }
    
    const onSearchTableRowClick = coupon => {
        if (search === coupon.Code) {
            setSearch('')
            setSelectedCoupon('')
        } else {
            setSearch(coupon.Code)
            setSelectedCoupon(coupon.Uid)
        }
    }

    const onSearchTableRowDoubleClick = coupon => {
        applyCoupon(coupon)
    }
    
    
    const couponColumns = useMemo(() => [
        {
            Header: 'Code',
            accessor: 'Code',
        },
        {
            Header: 'Name',
            accessor: 'Name',
        },
        {
            Header: 'Quantity Available',
            accessor: 'QuantityAvailable',
            Cell: ({row: {original}}) => <p className={'table-text'}>{original.QuantityAvailable === null ? '' : `${original.QuantityAvailable} available`}</p>
        }
    ], [])

    const appliedCouponColumns = useMemo(() => [
        {
            Header: 'Code',
            accessor: 'Code',
        },
        {
            Header: 'Name',
            accessor: 'Name',
        },
        {
            Header: 'Quantity',
            accessor: 'QuantityApplied',
            Cell: ({row: {original}}) => original.QuantityApplied === 1 ? <></> : original.QuantityApplied === 0 ? 'Not Valid' : <>{`x${original.QuantityApplied}`}</>
        }
    ], [])
    
    return (
        <div className={'container'}>
            <Header title={'Apply Coupon'}/>
            {/*{cartStatus === 'success' && !cartResponse && <div> <p>Unable to find cart</p><Button onClick={() => onSnippetComplete(returnUrl)} variant='contained' color={'primary'}>Close</Button></div>}*/}
            {coupons && cartStatus === 'success' &&
                <>
                    <div className={'snippetContainer center'}>
                        <form onSubmit={findCoupons}>
                            <Grid container>
                                <Grid item sm={8} md={9}>
                                    <TextInput required={false} id={'Coupon-Search'} value={search} label={''} variant={'outlined'} width={'100%'}
                                               handleChange={handleChange}/>
                                </Grid>
                                <Grid item sm={4} md={3}>
                                    <Button type={'submit'} style={{marginTop: '15px'}} variant='contained' color={'primary'}>Find / Apply</Button>
                                </Grid>
                            </Grid>
                        </form>
                        <br />
                        {!showOptions && 
                            <>
                                <div>
                                    <p className={'bold left'} style={{display: 'inline'}}>Applied Coupons</p>
                                    {selectedCoupon ? <Button className={'right'} variant={'contained'} color={'primary'} onClick={removeCoupon}>Remove</Button> : <></>}
                                </div>
                                <div className={`table-wrapper-div`}>
                                    <ClickableRowTable columns={appliedCouponColumns} data={appliedCoupons} hideHeader={true} onRowClick={onAppliedTableRowClick} selected={selectedCoupon}/>
                                </div>
                            </>
                        }
                        {showOptions &&
                            <>
                                <p className={'bold left'}>Select Coupons to Apply</p>
                                <div className={`table-wrapper-div`}>
                                    {options.length > 0 && 
                                        <ClickableRowTable columns={couponColumns} data={options} hideHeader={true} onRowClick={onSearchTableRowClick} selected={selectedCoupon} onDoubleClick={onSearchTableRowDoubleClick}/>
                                    }
                                    {options.length === 0 && <p>No Coupons Found</p>}
                                </div>
                            </>
                        }
                        <CloseButton onClose={() => onSnippetComplete(returnUrl)} label={showOptions ? 'Cancel' : 'Close'}/>
                    </div>
                </>
            }
            {(couponsStatus === 'loading' || apply.status === 'loading' || cartStatus === 'loading' || remove.status === 'loading') && <LoadingSpinner />}
        </div>
    )
}